import { useGeneralSchemaQuery } from "./components/hooks/use-general-schema-query"
import { usePageSpecificSchemaQuery } from "./components/hooks/use-page-specific-schema-query"

export function GeneralSchemaFunction() {
  const {
    wpgraphql,
    site,
    query1,
    forRatingValuePart1,
    forRatingValuePart2,
  } = useGeneralSchemaQuery()

  const data = wpgraphql.nexvelSchemaMarkup.nexvelschema

  return {
    services: data.services,
    cities: data.cities,
    businessName: data.businessName,
    siteUrl: site.siteMetadata.siteUrl,
    businessType: data.businessType,
    logo: data.logo.sourceUrl,
    image: data.image.sourceUrl,
    businessDescription: data.describeYourBusiness,
    phoneNumber: data.phoneNumber,
    address: data.streetAddress,
    city: data.city,
    state: data.state,
    zip: data.zipCode,
    openSunday: data.hoursOfOperation.sunday.areYouOpen,
    sundayClosingTime: `${data.hoursOfOperation.sunday.close.closeHour}:${data.hoursOfOperation.sunday.close.closeMinute} ${data.hoursOfOperation.sunday.close.amorpm}`,
    sundayOpeningTime: `${data.hoursOfOperation.sunday.open.openHour}:${data.hoursOfOperation.sunday.open.openMinute} ${data.hoursOfOperation.sunday.open.amorpm}`,
    openMonday: data.hoursOfOperation.monday.areYouOpen,
    mondayClosingTime: `${data.hoursOfOperation.monday.close.closeHour}:${data.hoursOfOperation.monday.close.closeMinute} ${data.hoursOfOperation.monday.close.amorpm}`,
    mondayOpeningTime: `${data.hoursOfOperation.monday.open.openHour}:${data.hoursOfOperation.monday.open.openMinute} ${data.hoursOfOperation.monday.open.amorpm}`,
    openTuesday: data.hoursOfOperation.tuesday.areYouOpen,
    tuesdayClosingTime: `${data.hoursOfOperation.tuesday.close.closeHour}:${data.hoursOfOperation.tuesday.close.closeMinute} ${data.hoursOfOperation.tuesday.close.amorpm}`,
    tuesdayOpeningTime: `${data.hoursOfOperation.tuesday.open.openHour}:${data.hoursOfOperation.tuesday.open.openMinute} ${data.hoursOfOperation.tuesday.open.amorpm}`,
    openWednesday: data.hoursOfOperation.wednesday.areYouOpen,
    wednesdayClosingTime: `${data.hoursOfOperation.wednesday.close.closeHour}:${data.hoursOfOperation.wednesday.close.closeMinute} ${data.hoursOfOperation.wednesday.close.amorpm}`,
    wednesdayOpeningTime: `${data.hoursOfOperation.wednesday.open.openHour}:${data.hoursOfOperation.wednesday.open.openMinute} ${data.hoursOfOperation.wednesday.open.amorpm}`,
    openThursday: data.hoursOfOperation.thursday.areYouOpen,
    thursdayClosingTime: `${data.hoursOfOperation.thursday.close.closeHour}:${data.hoursOfOperation.thursday.close.closeMinute} ${data.hoursOfOperation.thursday.close.amorpm}`,
    thursdayOpeningTime: `${data.hoursOfOperation.thursday.open.openHour}:${data.hoursOfOperation.thursday.open.openMinute} ${data.hoursOfOperation.thursday.open.amorpm}`,
    openFriday: data.hoursOfOperation.friday.areYouOpen,
    fridayClosingTime: `${data.hoursOfOperation.friday.close.closeHour}:${data.hoursOfOperation.friday.close.closeMinute} ${data.hoursOfOperation.friday.close.amorpm}`,
    fridayOpeningTime: `${data.hoursOfOperation.friday.open.openHour}:${data.hoursOfOperation.friday.open.openMinute} ${data.hoursOfOperation.friday.open.amorpm}`,
    openSaturday: data.hoursOfOperation.saturday.areYouOpen,
    saturdayClosingTime: `${data.hoursOfOperation.saturday.close.closeHour}:${data.hoursOfOperation.saturday.close.closeMinute} ${data.hoursOfOperation.saturday.close.amorpm}`,
    saturdayOpeningTime: `${data.hoursOfOperation.saturday.open.openHour}:${data.hoursOfOperation.saturday.open.openMinute} ${data.hoursOfOperation.saturday.open.amorpm}`,
    reviewsForRatingValuePart1:
      forRatingValuePart1?.nodes.length &&
      forRatingValuePart1.nodes.map((rating, i) => {
        return {
          index: i,
          rating: rating.rating,
        }
      }),
    reviewsForRatingValuePart2:
      forRatingValuePart2?.nodes.length &&
      forRatingValuePart2.nodes.map((rating, i) => {
        return {
          index: i,
          rating: rating.rating,
        }
      }),
    reviewsQuery:
      query1?.nodes.length &&
      query1.nodes.map((review, i) => {
        return {
          index: i,
          id: review.id,
          sourceType: review.sourceType,
          reviewDate: review.reviewDate,
          rating: review.rating,
          reviewerFirstName: review.reviewer.firstName,
          reviewerLastName: review.reviewer.lastName,
          comments: review.comments,
          businessName: review.businessName,
        }
      }),
    awards: data.awardsSchema?.map(award => {
      return {
        award: award.awardSchema,
      }
    }),
    brands: data.brandsSchema?.map(brand => {
      return {
        brand: brand.brandSchema,
      }
    }),
    certifications: data.certificationsSchema?.map(certification => {
      return {
        certification: certification.certificationSchema,
      }
    }),
    languages: data.languagesSchema?.map(language => {
      return {
        language: language.languageSchema,
      }
    }),
    organizations: data.organizationsSchema?.map(organization => {
      return {
        organization: organization.organizationSchema,
      }
    }),
    socialProfilesDirectoryListings: data.socialMediaProfilesAndDirectoryListingsSchema?.map(
      item => {
        return {
          socialProfileDirectory:
            item.socialMediaProfileOrDirectoryListingSchema,
        }
      }
    ),
  }
}

export function PageSpecificSchemaFunction(data) {
  const pageData =
    data.subpage !== undefined
      ? data.subpage
      : data.fleetpage !== undefined
      ? data.fleetpage
      : data.wordpressPage !== undefined
      ? data.wordpressPage
      : data.techpage !== undefined
      ? data.techpage
      : data.wordpressPost
  const { wpgraphql } = usePageSpecificSchemaQuery()

  const pageData2 =
    data.subpage !== undefined
      ? data.wpgraphql.page
      : data.fleetpage !== undefined
      ? data.wpgraphql.page
      : data.wordpressPage !== undefined
      ? data.wpgraphql.page
      : data.techpage !== undefined
      ? data.wpgraphql.page
      : data.wpgraphql.post

  return {
    businessName: wpgraphql.nexvelSchemaMarkup.nexvelschema.businessName,
    businessType: wpgraphql.nexvelSchemaMarkup.nexvelschema.businessType,
    phoneNumber: wpgraphql.nexvelSchemaMarkup.nexvelschema.phoneNumber,
    streetAddress: wpgraphql.nexvelSchemaMarkup.nexvelschema.streetAddress,
    city: wpgraphql.nexvelSchemaMarkup.nexvelschema.city,
    state: wpgraphql.nexvelSchemaMarkup.nexvelschema.state,
    zipCode: wpgraphql.nexvelSchemaMarkup.nexvelschema.zipCode,
    logo: wpgraphql.nexvelSchemaMarkup.nexvelschema.logo.sourceUrl,
    blogPageUrl: wpgraphql.nexvelSchemaMarkup.nexvelschema.blogPageUrl,
    aboutPageUrl: wpgraphql.nexvelSchemaMarkup.nexvelschema.aboutPageUrl,
    contactPageUrl: wpgraphql.nexvelSchemaMarkup.nexvelschema.contactPageUrl,
    siteUrl: data.site.siteMetadata.siteUrl,
    uri: pageData !== undefined ? pageData.uri : "/blog/",
    title: pageData !== undefined ? pageData.title : "Blog",
    datePublished: pageData?.date,
    dateModified: pageData?.modified,
    image:
      pageData?.seo?.opengraphImage !== null
        ? data.site.siteMetadata.siteUrl +
          pageData?.seo?.opengraphImage.localFile.sourceUrl
        : "/blank.jpg",
    author: `${pageData?.author?.node.firstName} ${pageData?.author?.node.lastName}`,
    categories:
      pageData?.categories?.nodes.length &&
      pageData?.categories.nodes.map((category, i) => {
        return {
          index: i,
          name: category.name,
        }
      }),
    tags:
      pageData?.tags?.nodes.length &&
      pageData?.tags.nodes.map((tag, i) => {
        return {
          index: i,
          name: tag.name,
        }
      }),
    articleBody: pageData?.content,
    post: data.wordpressPost !== undefined,
    videos:
      pageData2?.nexvelschemapagesposts?.videos?.length &&
      pageData2?.nexvelschemapagesposts?.videos.map((video, i) => {
        return {
          index: i,
          title: video.title,
          url: video.url,
          description: video.description,
          thumbnailImage: video.thumbnailImage.localFile.publicURL,
          uploadDate: video.uploadDate,
        }
      }),
    questionsAndAnswers:
      pageData2?.nexvelschemapagesposts?.questionsAndAnswers?.length &&
      pageData2?.nexvelschemapagesposts?.questionsAndAnswers.map((qanda, i) => {
        return {
          index: i,
          question: qanda.question,
          answer: qanda.answer,
        }
      }),
    maps:
      pageData2?.nexvelschemapagesposts?.maps?.length &&
      pageData2?.nexvelschemapagesposts?.maps.map((map, i) => {
        return {
          index: i,
          url: map.mapUrl,
        }
      }),
    digitalDocuments:
      pageData2?.nexvelschemapagesposts?.digitaldocuments?.length &&
      pageData2?.nexvelschemapagesposts?.digitaldocuments.map((document, i) => {
        return {
          index: i,
          title: document.title,
        }
      }),
    images:
      pageData2?.nexvelschemapagesposts?.images?.length &&
      pageData2?.nexvelschemapagesposts?.images.map((item, i) => {
        return {
          index: i,
          image: item.image.localFile.sourceUrl,
          date: item.image.date,
          description: item.image.description,
          title: item.image.title,
        }
      }),
    areaServedGeneral: data.wordpressAcfOptions?.options.cities.map(city => {
      return {
        city: city.city,
      }
    }),
    areaServedPageSpecific:
      pageData2?.nexvelschemapagesposts?.areaServedLandingPageSchema,
    serviceGeneral: data.wordpressAcfOptions?.options.services.map(service => {
      return {
        serviceName: service.service,
        relevantPage: service.linkToRelevantPageOnYourWebsite,
      }
    }),
    servicePageSpecific: {
      serviceName:
        pageData2?.nexvelschemapagesposts?.serviceSchema.serviceNameSchema,
      relevantPage:
        pageData2?.nexvelschemapagesposts?.serviceSchema
          .linkToRelevantServicePageSchema,
    },
    keywords: pageData2?.nexvelschemapagesposts?.keywordsSchema?.map(item => {
      return {
        keyword: item.keywordSchema,
      }
    }),
    localAwards: pageData2?.nexvelschemapagesposts?.localAwardsSchema?.map(
      item => {
        return {
          localAward: item.localAwardSchema,
        }
      }
    ),
    latitude: pageData2?.nexvelschemapagesposts?.latitudeSchema,
    longitude: pageData2?.nexvelschemapagesposts?.longitudeSchema,
    relevantExternalPages: pageData2?.nexvelschemapagesposts?.relevantExternalPagesSchema?.map(
      item => {
        return {
          relevantExternalPage: item.relevantExternalPageSchema,
        }
      }
    ),
  }
}
